import { useQuery, UseQueryResult } from "react-query";
import axios from "axios";

import { setUser } from "../store/userReducer";

const fecthQuestion = async () => {
  const { data } = await axios.get("/?do=victory&json=true");

  return data;
};
const useQuestions = (): UseQueryResult<any> => {
  const answer = useQuery("questions", () => fecthQuestion(), { retry: false });
  return { ...answer };
};

export default useQuestions;
