import React from "react";
import Next from "../button/next";
import Back from "../button/Back";
import s from "./FooterBlock.module.scss";

type Props = {
  NextFun: Function;
  BackFun?: Function;
  TextPrice?: string;
  BackTrue?: boolean;
};
const FooterBlock = ({ NextFun, BackFun, TextPrice, BackTrue }: Props) => {
  return (
    <div className={s.downblock}>
      <div className={s.buttonsblock}>
        {BackFun && BackTrue && (
          <Back styleArr={{ marginRight: "2rem" }} DoIt={() => BackFun()} />
        )}
        <Next DoIt={() => NextFun()}>
          <>
            Далее
            <svg
              width="23"
              height="24"
              viewBox="0 0 23 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.4272 12.2471C15.8177 11.8566 15.8177 11.2235 15.4272 10.8329L9.06319 4.46897C8.67267 4.07845 8.03951 4.07845 7.64898 4.46897C7.25846 4.8595 7.25846 5.49266 7.64898 5.88318L13.3058 11.54L7.64898 17.1969C7.25846 17.5874 7.25846 18.2206 7.64898 18.6111C8.03951 19.0016 8.67267 19.0016 9.06319 18.6111L15.4272 12.2471ZM13.8 12.54H14.72V10.54H13.8V12.54Z"
                fill="white"
              />
            </svg>
          </>
        </Next>
      </div>
      {TextPrice && (
        <div className={s.priceBlock}>
          <div> ~ {TextPrice} ₽</div>
          <p>Ориентировочная стоимость проекта</p>
        </div>
      )}
    </div>
  );
};

export default FooterBlock;
