import React from "react";
import AppView from "./AppView";
import { BrowserRouter } from "react-router-dom";
import { QueryClientProvider } from "react-query";
import queryClient from "./components/Api/queryClient";
import createStore from "./components/store/rootReducer";
import { ReactQueryDevtools } from "react-query/devtools";
import { Provider } from "react-redux";

declare global {
  interface Window {
    user: any;
  }
}
const App = () => {
  const store = createStore({
    userReducer: {
      user: window.user ? window.user : false,
    },
  });

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <AppView />
        </BrowserRouter>
        {/* <ReactQueryDevtools initialIsOpen={true} /> */}
      </QueryClientProvider>
    </Provider>
  );
};

export default App;
