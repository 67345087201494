import React, { useState, useEffect } from "react";
import s from "./InputsNumber.module.scss";
import _ from "lodash";
type Props = {
  styleArr?: any;
  KeyAnswer: string | number;
  change: Function;
  trueAnswer: string | any;
  val?: any;
};
const InputsNumber = ({
  styleArr,
  KeyAnswer,
  change,
  trueAnswer,
  val,
}: Props) => {
  const [num, setNum] = useState<number>(val ? val : 0);
  const ChangeCustom = (val: number, del: number) => {
    if (val + del === 0) {
      change(KeyAnswer, "");
    } else {
      change(KeyAnswer, val + del, val);
    }
    setNum(val + del);
  };

  useEffect(() => {
    if (val) {
      val.map((item: any) => {
        console.log(val);
        if (item.customFiled !== "") {
          setNum(Number(item.customFiled));
        }
      });
    }
  }, [val]);

  return (
    <div className={s.InputsNumber} style={styleArr && styleArr}>
      <p>Кол-во каналов анонсирования</p>
      <div className="inputsNumber">
        <div
          className={s.minus}
          onClick={(e) => {
            e.stopPropagation();

            if (num > 0) {
              ChangeCustom(num, -1);
            }
          }}
        >
          <svg
            width="16"
            height="14"
            viewBox="0 0 16 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15 6.99951L0.999757 6.99955"
              stroke="#513CCC"
              stroke-width="2"
              stroke-linecap="round"
            />
          </svg>
        </div>
        {num}
        <div
          className={s.plus}
          onClick={(e) => {
            e.stopPropagation();
            ChangeCustom(num, 1);
          }}
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8 0.999512L7.99996 14.9998"
              stroke="#513CCC"
              stroke-width="2"
              stroke-linecap="round"
            />
            <path
              d="M15 7.99951L0.999757 7.99955"
              stroke="#513CCC"
              stroke-width="2"
              stroke-linecap="round"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default InputsNumber;
