import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import MainPage from "./components/Pages/MainPage";
import QuestionPage from "./components/Pages/QuestionPage";
import LoginPage from "./components/Pages/LoginPage";
import Header from "./components/common/Header";
import { useSelector, useDispatch } from "react-redux";
import {
  setSuccess,
  setError,
  setErrorSend,
} from "./components/store/modalReducer";
import { setUser } from "./components/store/userReducer";
import "./App.css";
import useQuestions from "./components/Api/useQuestions";
import Modal from "react-modal";

const AppView = () => {
  const dispatch = useDispatch();

  const query = useQuestions();
  const url = useLocation();
  const userAuth = useSelector(({ userReducer }: any) => userReducer.user);
  const successModal = useSelector(
    ({ modalReducer }: any) => modalReducer.success
  );
  const errorModal = useSelector(({ modalReducer }: any) => modalReducer.error);
  const errorSend = useSelector(
    ({ modalReducer }: any) => modalReducer.errorSend
  );

  useEffect(() => {
    if (query.data) {
      if (query.data.user === null) {
        dispatch(setUser(false));
      }
    }
  }, [query.data]);
  return (
    <div>
      <Header infoData={query} />
      {userAuth ? (
        url.search === "" && url.pathname === "/" ? (
          <MainPage />
        ) : (
          <QuestionPage infoData={query} />
        )
      ) : (
        <LoginPage restart={query.refetch} />
      )}
      <Modal
        ariaHideApp={false}
        isOpen={errorModal}
        onRequestClose={() => dispatch(setError(false))}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.46)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
          content: {
            maxWidth: "fit-content",
            maxHeight: "1000px",
            height: "fit-content",
            border: "none",
            position: "relative",
            padding: "3rem",
          },
        }}
      >
        <p className="Error">Ошибка! Выберите вариант ответа</p>
      </Modal>
      <Modal
        ariaHideApp={false}
        isOpen={errorSend}
        onRequestClose={() => dispatch(setErrorSend(false))}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.46)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
          content: {
            maxWidth: "fit-content",
            maxHeight: "1000px",
            height: "fit-content",
            border: "none",
            position: "relative",
            padding: "3rem",
          },
        }}
      >
        <p className="Error">Ошибка! Сообщение не отправлено!</p>
      </Modal>
      <Modal
        ariaHideApp={false}
        isOpen={successModal}
        onRequestClose={() => dispatch(setSuccess(false))}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.46)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
          content: {
            maxWidth: "fit-content",
            maxHeight: "1000px",
            height: "fit-content",
            border: "none",
            position: "relative",
            padding: "3rem",
          },
        }}
      >
        <p className="Success">
          Сообщение успешно отправлено! Проверьте спам🔥
        </p>
      </Modal>
    </div>
  );
};

export default AppView;
