import React, { useState } from "react";
import s from "./LoginPage.module.scss";
import Next from "../../common/button/next";
import InputsText from "../../common/Inputs/InputsText";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUser } from "../../store/userReducer";

const LoginPage = ({ restart }: { restart: Function }) => {
  const [login, SetLogin] = useState<string>("");
  const [password, SetPas] = useState<string>("");
  const dispatch = useDispatch();

  const GoTest = () => {
    const newData = new FormData();
    newData.append("password", password);
    newData.append("login", login);
    axios({
      method: "post",
      url: `/?do=login&json`,
      data: newData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response: any) => {
        restart();
        if (response.data.success) {
          dispatch(setUser(true));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div className={` ${s.container}`}>
      <div className="container">
        <div className={` ${s.center}`}>
          <h1>Авторизация!</h1>
          <InputsText topText="Введите логин" val={login} change={SetLogin} />
          <InputsText topText="Введите пароль" val={password} change={SetPas} />
          <Next DoIt={GoTest}>
            <>
              Войти
              <svg
                width="23"
                height="24"
                viewBox="0 0 23 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.4272 12.2471C15.8177 11.8566 15.8177 11.2235 15.4272 10.8329L9.06319 4.46897C8.67267 4.07845 8.03951 4.07845 7.64898 4.46897C7.25846 4.8595 7.25846 5.49266 7.64898 5.88318L13.3058 11.54L7.64898 17.1969C7.25846 17.5874 7.25846 18.2206 7.64898 18.6111C8.03951 19.0016 8.67267 19.0016 9.06319 18.6111L15.4272 12.2471ZM13.8 12.54H14.72V10.54H13.8V12.54Z"
                  fill="white"
                />
              </svg>
            </>
          </Next>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
