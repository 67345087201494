import React, { useState } from "react";
import s from "./ThanksPage.module.scss";
import InputsText from "../../common/Inputs/InputsText";
import Next from "../../common/button/next";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setSuccess, setErrorSend } from "../../store/modalReducer";
const ThanksPage = ({ table, price }: { table: any; price: number }) => {
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  const dispatch = useDispatch();
  const sendEmail = () => {
    const newData = new FormData();
    console.log(email);
    if (email.length < 5) {
      setError(true);
      return false;
    }

    newData.append("email", email);
    axios({
      method: "post",
      url: `/?do=victory&XML_DUMP&json`,
      data: newData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((e: any) => {
        if (e.data.EmailSent === 1) {
          dispatch(setSuccess(true));
        } else {
          dispatch(setErrorSend(true));
        }

        console.log("success");
        setEmail("");
      })
      .catch(() => {
        dispatch(setErrorSend(true));
        console.log("Erro");
        setEmail("");
      });
  };
  return (
    <>
      <div className={s.blockquest}>
        <h1>Спасибо!</h1>
        <p>Предварительный расчет Вашего проекта</p>
      </div>
      <div className={s.resultblock}>
        <div className={s.money}>
          <div className={s.flexmoney}>
            {table.map((item: any) => {
              if (item.answer !== "") {
                return (
                  <div>
                    <div className={s.checkblock}>
                      <svg
                        width="14"
                        height="10"
                        viewBox="0 0 14 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.650391 5.5L4.00039 8.5L11.5004 1"
                          stroke="white"
                          stroke-width="1.25"
                          stroke-linecap="round"
                        />
                      </svg>
                    </div>
                    <p>
                      {item.name}
                      &nbsp;
                      {item.answer}
                    </p>
                  </div>
                );
              }
            })}
          </div>
          <div>
            <div className={s.endprice}>~ {price} ₽</div>
            <p>Смета расчитана по стандартным срокам производства</p>
          </div>
        </div>
        <div className={`${s.mailform} ${error === true && s.error}`}>
          <InputsText
            placeholder="example@mail.ru"
            val={email}
            change={(val: any) => {
              setEmail(val);
              setError(false);
            }}
            topText="Введите свой e-mail, чтобы получить расчет на почту"
          />
          <Next
            styleArr={{ maxWidth: "100%", marginTop: "3rem" }}
            DoIt={sendEmail}
          >
            <>
              отправить на почту
              <svg
                width="19"
                height="14"
                viewBox="0 0 19 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.7 0.5H2.3C1.3073 0.5 0.5 1.22881 0.5 2.125V11.875C0.5 12.7712 1.3073 13.5 2.3 13.5H16.7C17.6927 13.5 18.5 12.7712 18.5 11.875V2.125C18.5 1.22881 17.6927 0.5 16.7 0.5ZM16.7 2.125V2.54019L9.5 7.59637L2.3 2.541V2.125H16.7ZM2.3 11.875V4.59825L8.9474 9.26606C9.105 9.37782 9.2996 9.43856 9.5 9.43856C9.7004 9.43856 9.895 9.37782 10.0526 9.26606L16.7 4.59825L16.7018 11.875H2.3Z"
                  fill="white"
                />
              </svg>
            </>
          </Next>
          <p className={s.somep}>
            Вы можете сохранить и скачать рассчет в excel-файле
          </p>
          <a href="/?do=victory&XML_DUMP" download target="_self">
            <Next
              DoIt={() => {}}
              styleArr={{ maxWidth: "100%", marginTop: "2rem" }}
            >
              <>
                сохранить в excel
                <svg
                  width="15"
                  height="18"
                  viewBox="0 0 15 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.644968 1.90077L10.072 0.503943C10.1252 0.496024 10.1794 0.500064 10.231 0.515791C10.2826 0.531517 10.3303 0.558562 10.3709 0.595092C10.4115 0.631623 10.444 0.676785 10.4664 0.727518C10.4887 0.778252 10.5002 0.83337 10.5002 0.889139V17.111C10.5002 17.1667 10.4887 17.2217 10.4664 17.2724C10.4441 17.323 10.4116 17.3682 10.3711 17.4047C10.3306 17.4412 10.283 17.4683 10.2315 17.484C10.18 17.4998 10.1259 17.504 10.0727 17.4962L0.644218 16.0993C0.465441 16.0729 0.301854 15.9805 0.183507 15.8389C0.0651606 15.6974 1.73284e-06 15.5163 0 15.3289V2.67116C1.73284e-06 2.48379 0.0651606 2.3027 0.183507 2.16118C0.301854 2.01965 0.465441 1.92719 0.644218 1.90077H0.644968ZM1.50067 3.34662V14.6535L9.0003 15.7655V2.2346L1.50067 3.34662ZM11.2502 14.4473H13.5001V3.55283H11.2502V1.99648H14.25C14.4489 1.99648 14.6397 2.07847 14.7803 2.2244C14.921 2.37034 15 2.56827 15 2.77466V15.2255C15 15.4318 14.921 15.6298 14.7803 15.7757C14.6397 15.9216 14.4489 16.0036 14.25 16.0036H11.2502V14.4473ZM6.15044 9.00005L8.25034 12.1128H6.45043L5.25049 10.3338L4.05055 12.1128H2.25064L4.35053 9.00005L2.25064 5.88736H4.05055L5.25049 7.66626L6.45043 5.88736H8.25034L6.15044 9.00005Z"
                    fill="white"
                  />
                </svg>
              </>
            </Next>
          </a>
          <a href="/?do=victory&CSV_DUMP " download target="_self">
            <Next
              DoIt={() => {}}
              styleArr={{ maxWidth: "100%", marginTop: "2rem" }}
            >
              <>
                сохранить в CSV
                <svg
                  width="15"
                  height="18"
                  viewBox="0 0 15 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.644968 1.90077L10.072 0.503943C10.1252 0.496024 10.1794 0.500064 10.231 0.515791C10.2826 0.531517 10.3303 0.558562 10.3709 0.595092C10.4115 0.631623 10.444 0.676785 10.4664 0.727518C10.4887 0.778252 10.5002 0.83337 10.5002 0.889139V17.111C10.5002 17.1667 10.4887 17.2217 10.4664 17.2724C10.4441 17.323 10.4116 17.3682 10.3711 17.4047C10.3306 17.4412 10.283 17.4683 10.2315 17.484C10.18 17.4998 10.1259 17.504 10.0727 17.4962L0.644218 16.0993C0.465441 16.0729 0.301854 15.9805 0.183507 15.8389C0.0651606 15.6974 1.73284e-06 15.5163 0 15.3289V2.67116C1.73284e-06 2.48379 0.0651606 2.3027 0.183507 2.16118C0.301854 2.01965 0.465441 1.92719 0.644218 1.90077H0.644968ZM1.50067 3.34662V14.6535L9.0003 15.7655V2.2346L1.50067 3.34662ZM11.2502 14.4473H13.5001V3.55283H11.2502V1.99648H14.25C14.4489 1.99648 14.6397 2.07847 14.7803 2.2244C14.921 2.37034 15 2.56827 15 2.77466V15.2255C15 15.4318 14.921 15.6298 14.7803 15.7757C14.6397 15.9216 14.4489 16.0036 14.25 16.0036H11.2502V14.4473ZM6.15044 9.00005L8.25034 12.1128H6.45043L5.25049 10.3338L4.05055 12.1128H2.25064L4.35053 9.00005L2.25064 5.88736H4.05055L5.25049 7.66626L6.45043 5.88736H8.25034L6.15044 9.00005Z"
                    fill="white"
                  />
                </svg>
              </>
            </Next>
          </a>
        </div>
      </div>
    </>
  );
};

export default ThanksPage;
