import React from "react";
import s from "./MainPage.module.scss";
import { useNavigate } from "react-router-dom";
import FooterBlock from "../../common/footer/FooterBlock";
const MainPage = () => {
  const navigate = useNavigate();

  const GoTest = () => {
    navigate("/?do=victory");
    // axios({
    //   method: "get",
    //   url: "/?do=victory",
    // })
    //   .then((response) => {
    //     console.log(response);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  };
  return (
    <div className={` ${s.container}`}>
      <div className="container">
        <div>
          <h1>Здравствуйте!</h1>
          <p>
            Пройдите небольшой опрос, чтобы рассчитать ориентировочную стоимость
            вашего проекта
          </p>
        </div>

        <FooterBlock NextFun={GoTest} />
      </div>
    </div>
  );
};

export default MainPage;
