import React, { useState } from "react";
import s from "./Header.module.scss";
import { NavLink } from "react-router-dom";
import urlimg from "../../img/logo.svg";
import axios from "axios";
import Modal from "react-modal";
const Header = ({ infoData }: { infoData: any }) => {
  const [OpenModal, SetOpen] = useState<boolean>(false);
  const reset = () => {
    axios.post(`/?do=victory&restart`).then(() => {
      infoData.refetch();
      SetOpen(false);
    });
  };

  return (
    <div className={s.headercontainer}>
      <NavLink to="/" className={s.left}>
        <img src={urlimg} alt="" />
      </NavLink>
      {!infoData.isLoading &&
        typeof infoData.data !== "undefined" &&
        infoData.data.Victory && (
          <>
            <div className={s.repeat}>
              <p>рассчитать новый проект</p>
              <div onClick={() => SetOpen(true)}>
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 3.99976L12 18"
                    stroke="#513CCC"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                  <path
                    d="M5 10.9998L19.0002 10.9998"
                    stroke="#513CCC"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
            </div>
            <Modal
              ariaHideApp={false}
              isOpen={OpenModal}
              onRequestClose={() => SetOpen(false)}
              style={{
                overlay: {
                  backgroundColor: "rgba(0, 0, 0, 0.46)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                },
                content: {
                  maxWidth: "fit-content",
                  maxHeight: "1000px",
                  height: "fit-content",
                  border: "none",
                  position: "relative",
                },
              }}
            >
              <p className={s.ModalP}>Вы уверенны в этом ?</p>
              <div className={s.ModalButtons}>
                <div onClick={() => reset()}>Да</div>
                <div
                  onClick={() => {
                    SetOpen(false);
                  }}
                >
                  Нет
                </div>
              </div>
            </Modal>
          </>
        )}
    </div>
  );
};

export default Header;
