import React, { useState } from "react";
import s from "./RadioButton.module.scss";
import _ from "lodash";
const RadioButton = ({
  answ,
  trueAnswer,
  change,
  KeyAnswer,
}: {
  answ: any;
  trueAnswer?: string;
  change: Function;
  KeyAnswer: string | number;
}) => {
  return (
    <div className={s.ContainerRadio}>
      <div>
        <div
          className={`${s.radio} ${
            _.isArray(trueAnswer) &&
            trueAnswer.filter((e: any) => e.ans === KeyAnswer).length > 0 &&
            s.check
          }`}
          onClick={() => {
            change(KeyAnswer, "");
          }}
        ></div>
        <p>{answ}</p>
      </div>
    </div>
  );
};

export default RadioButton;
