import React, { useState, useEffect } from "react";
import s from "./QuestionPage.module.scss";
import FooterBlock from "../../common/footer/FooterBlock";
import RadioButton from "../../common/button/RadioButton";
import InputsTextQuestions from "../../common/Inputs/InputsTextQuestions";
import CheckedBlock from "../../common/button/CheckedBlock";
import InputsNumber from "../../common/Inputs/InputsNumber";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import ThanksPage from "../ThanksPage";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setError } from "../../store/modalReducer";
const QuestionPage = ({ infoData }: { infoData: any }) => {
  const dispatch = useDispatch();
  const [answer, setAnswer] = useState<string | any>();

  const setAnswerCustom = (
    ans: string,
    customAnswer: number | string,
    oldVal?: number | string
  ) => {
    if (infoData.data.Question.type === "checkbox") {
      if (oldVal) {
        //проверка на старое занчение custom поля

        if (_.isArray(answer)) {
          //проверка на наличие других элементов
          //если есть элементы, то находит старый, удаляем, и добавляем новый
          setAnswer([
            ...answer.filter((item: any) => {
              if (item.customFiled !== oldVal) return item;
            }),
            { ans: ans, customFiled: customAnswer },
          ]);
        } else {
          //если нету, то просто добавляем
          setAnswer([...answer, { ans: ans, customFiled: customAnswer }]);
        }
      } else {
        if (_.isArray(answer)) {
          if (answer.filter((e) => e.ans === ans).length > 0) {
            setAnswer([
              ...answer.filter((item: any) => {
                if (item.ans !== ans) return item;
              }),
            ]);
          } else {
            setAnswer([...answer, { ans: ans, customFiled: customAnswer }]);
          }
        } else {
          setAnswer([{ ans: ans, customFiled: customAnswer }]);
        }
      }
    } else {
      setAnswer([{ ans: ans, customFiled: customAnswer }]);
    }
  };
  useEffect(() => {
    console.log(answer);
  }, [answer]);
  const back = (key: number) => {
    if (infoData.data.QuestionCurrent === 1) {
      navigate("/");
    } else {
      axios.post("/?do=victory&back").then(() => {
        if (key !== 1 && infoData.data.AnswerSet.length > 0) {
          if (
            infoData.data.AnswerSet[key - 2].questionObj.type === "checkbox"
          ) {
            let newArr: any = [];
            infoData.data.AnswerSet[key - 2].allAnswers.map((item: any) => {
              let cust: string | number =
                item.rawValue === 0 ? "" : item.rawValue.toString();
              let ans: number = Object.values(
                infoData.data.AnswerSet[key - 2].questionObj.answers
              ).indexOf(item.answerText);
              newArr.push({ ans: String(ans + 1), customFiled: cust });
            });
            setAnswer(newArr);
          } else {
            let ans: string =
              infoData.data.AnswerSet[key - 2].answer.toString();
            let cust: string | number =
              infoData.data.AnswerSet[
                key - 2
              ].allAnswers[0].rawValue.toString();
            if (cust === "0") {
              setAnswer([{ ans, customFiled: "" }]);
            } else {
              setAnswer([{ ans, customFiled: cust }]);
            }
          }
        }

        infoData.refetch();
      });
    }
  };
  const SendAnswer = () => {
    const newData = new FormData();

    if (!answer || answer === "" || answer.length < 1) {
      if (infoData.data.Question.type === "checkbox") {
        const t = new Boolean(true).toString();
        newData.append("skip", t);
      } else {
        dispatch(setError(true));
        return false;
      }
    }

    newData.append("remainingTime", "1");
    if (_.isArray(answer)) {
      answer.map((item: any, ind: number) => {
        if (item.customFiled !== "") {
          newData.append(`customField${item.ans}`, item.customFiled);
        }
        if (infoData.data.Question.type === "checkbox") {
          newData.append("answer[]", item.ans);
        } else {
          newData.append("answer", item.ans);
        }
      });
    } else {
      if (infoData.data.Question.type !== "checkbox") {
        dispatch(setError(true));
        return false;
      }
    }

    axios({
      method: "post",
      url: `/?do=victory&json`,
      data: newData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response: any) => {
        setAnswer("");
        infoData.refetch();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const navigate = useNavigate();
  return (
    <div className={` ${s.container}`}>
      <div className="container">
        {infoData.isLoading ||
        typeof infoData.data === "undefined" ||
        typeof infoData.data.Question === "undefined" ? (
          "загрузка"
        ) : infoData.data.Victory === false ? (
          <>
            <div className={s.blockquest}>
              <div className={s.numberquestion}>
                вопрос {infoData.data.QuestionCurrent} из{" "}
                {infoData.data.QuestionsTotal}
              </div>
              <h1>{infoData.data.Question.title}</h1>
              <div className={s.typequest}>
                {infoData.data.Question.type !== "checkbox"
                  ? Object.keys(infoData.data.Question.answers).map((item) => {
                      if (infoData.data.Question.customFields[item]) {
                        return (
                          <InputsTextQuestions
                            change={setAnswerCustom}
                            placeholder={infoData.data.Question.answers[item]}
                            KeyAnswer={item}
                            trueAnswer={answer}
                            numb={true}
                            val={
                              _.isArray(answer) &&
                              answer[0].customFiled !== "" &&
                              answer[0].customFiled !== "0" &&
                              answer[0].customFiled
                            }
                            // topText="Введите сумму, например: 100 000 ₽"
                          />
                        );
                      } else {
                        return (
                          <RadioButton
                            answ={infoData.data.Question.answers[item]}
                            KeyAnswer={item}
                            change={setAnswerCustom}
                            trueAnswer={answer}
                          />
                        );
                      }
                    })
                  : Object.keys(infoData.data.Question.answers).map((item) => {
                      if (infoData.data.Question.customFields[item]) {
                        return (
                          // <InputsText
                          //   change={setAnswerCustomInput}
                          //   placeholder={infoData.data.Question.answers[item]}
                          //   KeyAnswer={item}
                          //   // topText="Введите сумму, например: 100 000 ₽"
                          // />
                          <InputsNumber
                            styleArr={{
                              paddingTop: "2rem",
                              borderTop: "0.5px solid #B9B9B9",
                              maxWidth: "40rem",
                            }}
                            KeyAnswer={item}
                            change={setAnswerCustom}
                            trueAnswer={answer}
                            val={
                              _.isArray(answer) && answer.length > 0 && answer
                            }
                          />
                        );
                      } else {
                        return (
                          <CheckedBlock
                            answ={infoData.data.Question.answers[item]}
                            KeyAnswer={item}
                            change={setAnswerCustom}
                            trueAnswer={answer}
                          />
                        );
                      }
                    })}
              </div>
            </div>
            <FooterBlock
              NextFun={() => {
                SendAnswer();
              }}
              BackTrue={infoData.data.BackAllowed}
              BackFun={() => back(infoData.data.QuestionCurrent)}
              TextPrice={infoData.data.Score}
            />
          </>
        ) : (
          <ThanksPage table={infoData.data.Table} price={infoData.data.Score} />
        )}
      </div>
    </div>
  );
};

export default QuestionPage;
