import React from "react";
import s from "./Back.module.scss";
type Props = {
  styleArr?: any;
  DoIt: Function;
};
const Back = ({ styleArr, DoIt }: Props) => {
  return (
    <div
      className={s.backbutton}
      style={styleArr && styleArr}
      onClick={() => DoIt()}
    >
      <svg
        width="9"
        height="15"
        viewBox="0 0 9 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.572844 8.24715C0.18232 7.85662 0.18232 7.22346 0.572844 6.83293L6.93681 0.468971C7.32733 0.0784469 7.96049 0.0784469 8.35102 0.468971C8.74154 0.859496 8.74154 1.49266 8.35102 1.88318L2.69416 7.54004L8.35102 13.1969C8.74154 13.5874 8.74154 14.2206 8.35102 14.6111C7.96049 15.0016 7.32733 15.0016 6.93681 14.6111L0.572844 8.24715ZM2.19995 8.54004H1.27995V6.54004H2.19995V8.54004Z"
          fill="#513CCC"
        />
      </svg>
    </div>
  );
};

export default Back;
