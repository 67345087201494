import {
  createStore as reduxCreateStore,
  combineReducers,
  PreloadedState,
} from "redux";

import userReducer from "./userReducer";
import modalReducer from "./modalReducer";

const reducers = combineReducers({
  userReducer,
  modalReducer,
});

export type RootState = ReturnType<typeof reducers>;

const createStore = (preloadedState?: PreloadedState<RootState>) =>
  reduxCreateStore(reducers, preloadedState);

export default createStore;
