import React from "react";
import s from "./inputsText.module.scss";

type Props = {
  topText?: string;
  val: string;
  placeholder?: string;
  change: Function;
};

const InputsText = ({ topText, val, placeholder, change }: Props) => {
  return (
    <div className={s.mainInputsText}>
      {topText ? <p>{topText}</p> : ""}

      <input
        placeholder={placeholder}
        type="text"
        value={val}
        onChange={(e: any) => {
          change(e.target.value);
        }}
      />
    </div>
  );
};

export default InputsText;
