const SET_USER = "SET_USER";

type InitialStateType = {
  user: boolean | null;
};

const initialState: InitialStateType = {
  user: false,
};

type SetUserType = {
  type: typeof SET_USER;
  user: boolean;
};
export const setUser = (user: boolean): SetUserType => ({
  type: SET_USER,
  user,
});

const userReducer = (state = initialState, action: any): InitialStateType => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.user,
      };

    default:
      return state;
  }
};

export default userReducer;
