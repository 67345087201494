const SET_SUCCESS = "SET_SUCCESS";
const SET_ERROR = "SET_ERROR";
const SET_ERRORSEND = "SET_ERRORSEND";
type SetSuccessType = {
  type: typeof SET_SUCCESS;
  success: boolean;
};
export const setSuccess = (success: boolean): SetSuccessType => ({
  type: SET_SUCCESS,
  success,
});

type SetErrorType = {
  type: typeof SET_ERROR;
  error: boolean;
};
export const setError = (error: boolean): SetErrorType => ({
  type: SET_ERROR,
  error,
});

type SetErrorSendType = {
  type: typeof SET_ERRORSEND;
  errorSend: boolean;
};
export const setErrorSend = (errorSend: boolean): SetErrorSendType => ({
  type: SET_ERRORSEND,
  errorSend,
});

type InitialStateType = {
  success: boolean;
  error: boolean;
  headerModal: boolean;
  errorSend: boolean;
};
const initialState: InitialStateType = {
  success: false,
  error: false,
  headerModal: false,
  errorSend: false,
};
const modalReducer = (state = initialState, action: any): InitialStateType => {
  switch (action.type) {
    case SET_SUCCESS:
      return {
        ...state,
        success: action.success,
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case SET_ERRORSEND:
      return {
        ...state,
        errorSend: action.errorSend,
      };
    default:
      return state;
  }
};

export default modalReducer;
