import React from "react";
import s from "./next.module.scss";
type Props = {
  styleArr?: any;
  children: React.ReactChild;
  DoIt: Function;
};
const Next = ({ styleArr, children, DoIt }: Props) => {
  return (
    <div
      className={s.bluebutton}
      style={styleArr && styleArr}
      onClick={() => DoIt()}
    >
      {children}
    </div>
  );
};

export default Next;
