import React, { useState, useEffect } from "react";
import s from "./inputsText.module.scss";

type Props = {
  topText?: string;
  val?: string;
  placeholder?: string;
  change: Function;
  KeyAnswer?: string;
  trueAnswer?: any;
  numb?: boolean;
};
const InputsTextQuestions = ({
  topText,
  val,
  placeholder,
  change,
  KeyAnswer,
  trueAnswer,
  numb,
}: Props) => {
  const [InputVal, setVal] = useState<string>(val ? val : "");

  const changeInput = (str: string) => {
    if (numb) {
      let newStr = str.replace(/\D+/g, "");
      if (newStr.length > 0) {
        setVal(newStr);
        change(KeyAnswer, newStr);
      }
    } else {
      setVal(str);
      change(KeyAnswer, str);
    }
  };
  useEffect(() => {
    if (trueAnswer && trueAnswer[0].ans !== KeyAnswer) {
      setVal("");
    }
  }, [trueAnswer]);

  return (
    <div className={s.mainInputsText}>
      {topText ? <p>{topText}</p> : ""}

      <input
        placeholder={placeholder}
        type="text"
        value={InputVal}
        onChange={(e: any) => {
          changeInput(e.target.value);
        }}
      />
    </div>
  );
};

export default InputsTextQuestions;
