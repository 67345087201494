import React, { useEffect, useState } from "react";
import s from "./CheckedBlock.module.scss";
import _ from "lodash";

const CheckedBlock = ({
  answ,
  trueAnswer,
  change,
  KeyAnswer,
}: {
  answ: any;
  trueAnswer?: string | any;
  change: Function;
  KeyAnswer: string | number;
}) => {
  const [check, setCheck] = useState<boolean>(false);

  useEffect(() => {
    let t: number = 0;
    _.isArray(trueAnswer) &&
      trueAnswer.length > 0 &&
      trueAnswer.map((item: any) => {
        if (item.ans === KeyAnswer) t = 1;
      });

    if (t > 0) {
      setCheck(true);
    } else {
      setCheck(false);
    }
  }, [trueAnswer]);
  return (
    <div className={s.ContainerRadio}>
      <div>
        <div
          className={`${s.input}  ${check && s.check}`}
          onClick={() => {
            change(KeyAnswer, "");
          }}
        >
          <svg
            width="13"
            height="10"
            viewBox="0 0 13 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.650391 5.5L4.00039 8.5L11.5004 1"
              stroke="white"
              stroke-width="1.25"
              stroke-linecap="round"
            />
          </svg>
        </div>
        <p>{answ}</p>
      </div>
    </div>
  );
};

export default CheckedBlock;
